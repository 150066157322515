<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In order to separate
        <chemical-latex content="Cr^3+" /> from <chemical-latex content="Ca^2+" />,
        <chemical-latex content="NaOH" />
        is added to an acidic solution that contains
        <number-value :value="concCa" unit="\text{M }\ce{Ca^2+}" />
        and
        <number-value :value="concCr" unit="\text{M }\ce{Cr^3+}." />
        Answer the following questions using the
        <stemble-latex content="$\text{K}_\text{sp}$" />
        values for chromium(III) hydroxide
        <stemble-latex content="$(6.3\times10^{-31})$" />
        and calcium hydroxide
        <stemble-latex content="$(5.02\times10^{-6}).$" />
        It can be assumed that the initial concentration of
        <chemical-latex content="OH-" />
        is zero.
      </p>

      <p class="mb-n3">a) Which salt will precipitate first?</p>

      <v-radio-group v-model="inputs.precipitatedSalt" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block mb-1" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        b) What is the hydroxide ion concentration at the saturation point of the less soluble salt?
      </p>

      <calculation-input
        v-model="inputs.concOHLeastSoluble"
        class="mb-5"
        prepend-text="$\ce{[OH-]}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) What is the hydroxide ion concentration at the saturation point of the more soluble salt?
      </p>

      <calculation-input
        v-model="inputs.concOHMostSoluble"
        class="mb-5"
        prepend-text="$\ce{[OH-]}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) What is the concentration of the less soluble cation at the hydroxide ion concentration
        determined in part c)?
      </p>

      <calculation-input
        v-model="inputs.concCationAtConcOHMostSoluble"
        class="mb-0"
        prepend-text="$\ce{[Cation^{n+}]}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalReactionInput from '@/tasks/components/inputs/ChemicalReactionInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import NumericCellEditor from '@/common/components/NumericCellEditor.vue';

export default {
  name: 'Question456',
  components: {
    NumericCellEditor,
    LatexNumber,
    NumberValue,
    ChemicalNotationInput,
    ChemicalReactionInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        precipitatedSalt: null,
        concOHLeastSoluble: null,
        concOHMostSoluble: null,
        concCationAtConcOHMostSoluble: null,
      },
      options1: [
        {expression: '$\\ce{Cr(OH)3}$', value: 'CrOH3'},
        {expression: '$\\ce{Ca(OH)2}$', value: 'CaOH2'},
        {expression: '$\\ce{NaOH}$', value: 'NaOH'},
        {expression: '$\\ce{NaCl}$', value: 'NaCl'},
      ],
    };
  },
  computed: {
    concCa() {
      return this.taskState.getValueBySymbol('concCa').content;
    },
    concCr() {
      return this.taskState.getValueBySymbol('concCr').content;
    },
  },
};
</script>
